var analytics = require('../segment');

$(document).ready(function () {
    var $logo = $('.logo-home');
    $logo.click(analytics.trackNavClicked);
    
    $('.one-trust-options').on('click', function() {
        window.OneTrust && window.OneTrust.ToggleInfoDisplay();
    });
});
