var CryptoJS = require('crypto-js');

var eventTypes = {
    CHECKOUT_STARTED: 'Checkout Started',
    CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
    CHECKOUT_STEP_VIEWED: 'Checkout Step Viewed',
    ORDER_COMPLETED: 'Order Completed',
    PAYMENT_ENTERED: 'Payment Info Entered',
    COUPON_ENTERED: 'Coupon Entered',
    COUPON_APPLIED: 'Coupon Applied',
    COUPON_DENIED: 'Coupon Denied',
    COUPON_REMOVED: 'Coupon Removed',
    IDME_VERIFIED: 'IDme Verified',
    NAV_CLICKED: 'Navigation Clicked',
    LINK_CLICKED: 'Link Clicked',
    CTA_CLICKED: 'CTA Clicked',
};

function setGUIDHashCookie(hash) {
    document.cookie = 'guidH' + '=' + (hash || '') + "; path=/";
}

function getEmailHash(email) {
    if (!email) {
        return null;
    }

    return CryptoJS.SHA256(email.toLowerCase()).toString(CryptoJS.enc.Base64);
}

function getItemPrice(item) {
    var listPrice = item.price.list && item.price.list.value;
    var salePrice = item.price.sales && item.price.sales.value;
    return listPrice != null ? listPrice : salePrice != null ? salePrice : 0;
}

function mapProductsData(products) {
    if (!products) {
        return [];
    }

    return products.map(function (product, i) {
        return {
            category: product.category,
            description: product.shortDescription,
            url: product.productUrl,
            image_url:
                product.images.small && product.images.small.length
                    ? product.images.small[0].url
                    : undefined,
            name: product.productName,
            position: i + 1,
            price: getItemPrice(product),
            product_id: product.id,
            quantity: product.quantity,
            sku: product.id,
            variant: product.productType,
        };
    });
}

function getUserDataFromCustomer(customer) {
    if (!customer) {
        return {};
    }
    var profile = customer.profile;
    var address = customer.preferredAddress;

    return {
        email: profile ? profile.email.toLowerCase() : null,
        firstName: profile ? profile.first_name : null,
        lastName: profile ? profile.last_name : null,
        phone: profile ? profile.phone : null,
        city: address ? address.city : null,
        state: address ? address.stateCode : null,
        country: address ? address.countryCode.value : null,
        address: address
            ? [
                  {
                      city: address.city,
                      country: address.countryCode.value,
                      phone: profile.phone,
                      state: address.stateCode,
                  },
              ]
            : null,
    };
}

function getUserDataFromOrder(order) {
    if (!order) {
        return {};
    }
    var address = order.shipping && order.shipping[0] && order.shipping[0].shippingAddress;
    return {
        email: order.orderEmail && order.orderEmail.toLowerCase(),
        firstName: address ? address.firstName : null,
        lastName: address ? address.lastName : null,
        phone: address ? address.phone : null,
        city: address ? address.city : null,
        state: address ? address.state : null,
        country: address ? address.country : null,
        address: address
            ? [
                  {
                      city: address.city,
                      country: address.country,
                      phone: address.phone,
                      state: address.state,
                  },
              ]
            : null,
    };
}

function getUserData(data) {
    return data.order ? getUserDataFromOrder(data.order) : getUserDataFromCustomer(data.customer);
}

function mapGiftData(productList, items, count) {
    if (!items) {
        return [];
    }

    return items.map(function (item, i) {
        productList.push({
            category: item.productName,
            description: item.productName,
            discount: 0,
            image_url:
            item.images.small && item.images.small.length
                    ? item.images.small[0].url
                    : undefined,
            name: item.productName,
            position: i + count,
            price: getItemPrice(item),
            product_id: item.id,
            quantity: item.quantity,
            sku: item.id,
            url: item.url
        });
    });
}

function getSession(config, storage) {
    function getId() {
        return (Math.random() + 1).toString(36).substring(6);
    }
    function getCampaign(keys) {
        if (!keys.length || !document.location.search) {
            return;
        }

        var query = new URLSearchParams(document.location.search);

        return keys.reduce(function (acc, key) {
            var queryValue = query.get(key);
            if (queryValue) {
                acc[key] = queryValue;
            }
            return acc;
        }, {});
    }

    var sessionStr = storage.getItem(config.session_data_key || 'session_data');
    var now = Date.now();

    if (sessionStr) {
        var session = JSON.parse(sessionStr);
        if (session.expires >= now) {
            return Object.assign({}, session, {
                expires: now + (config.session_duration || 30) * 60,
            });
        }
    }

    return {
        expires: now + (config.session_duration || 30) * 60,
        id: now + '.' + getId(),
        start: now,
        data: {
            landing_page: document.location.href,
            referrer: document.referrer,
            campaign: getCampaign(config.url_params),
        },
    };
}

var sessionConfig = {
    // storage key
    session_data_key: 'session_data',
    // in minutes
    session_duration: 30,
    // query params to check at session generation
    url_params: [
        'utm_id',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_source',
        'utm_medium',
        'utm_source_platform',
        'utm_creative_format',
        'utm_marketing_tactic',
        'gclid',
        'fbclid',
        'irclickid',
        'msclkid',
        'sscid',
        'ttclid',
    ],
};

function getCookieValue(name) {
    var cookiePair = document.cookie
        .split(';')
        .map(function (val) {
            return val.trimStart();
        })
        .find(function (val) {
            return val.startsWith(name);
        });
    return cookiePair && cookiePair.substring(cookiePair.indexOf('=') + 1);
}

function integrationsConfig() {
    var session = getSession(sessionConfig, localStorage);
    var campaign = session.data.campaign;
    return {
        'Google Analytics': {
            clientId: getCookieValue('_ga')
                ? getCookieValue('_ga').split('.').slice(-2).join('.')
                : undefined,
            gclid: campaign ? campaign.gclid : undefined,
        },
        'Facebook Conversions API (Actions)': {
            fbp: getCookieValue('_fbp'),
            fbclid: campaign ? campaign.gclid : undefined,
        }
    };
};

function getBaseData(data) {
    if (!data.order) {
        return null;
    }
    var userData = getUserData(data);
    var shipping = data.order.shipping && data.order.shipping[0];

    var products = data.order.items && data.order.items.items;
    var productList = products && mapProductsData(products);
    if (data.order.giftCardItems) {
        mapGiftData(productList, data.order.giftCardItems.items, productList.length);
    }
    return Object.assign(
        {
            checkout_id: data.order.uuid,
            step: data.currentStage,
            shipping_method:
                shipping && shipping.selectedShippingMethod && shipping.shippingAddress
                    ? shipping.selectedShippingMethod.ID
                    : null,
            products: productList,
            integrations: integrationsConfig()
        },
        userData
    );
}

/**
 * Wrapper for tracking segment events that attaches affiliation and brand properties
 * @param {string} type - The segment event types
 * @param {Object} props - The properties
 */
function track(type, props) {
    // console.log('track', type, props);
    window.analytics.track(
        type,
        Object.assign(
            {
                affiliation: 'website',
                basket_token: getCookie('basketJWT').substring(10) || '',
                brand: window.__site_id,
            },
            props
        )
    );
}

function trackCheckoutStepCompleted(data) {
    var baseData = getBaseData(data);
    track(
        eventTypes.CHECKOUT_STEP_COMPLETED,
        Object.assign(
            {
                source: 'traeger_website',
                source_detail: 'checkout',
                join_date: new Date().toISOString(),
                step: data.currentStage,
                email_subscribe: data.emailSubscribe ? 'opted_in' : null,
                sms_subscribe: data.smsSubscribe ? 'opted_in' : null,
            },
            baseData
        )
    );
}

function trackCheckoutStepViewed(data) {
    var baseData = getBaseData(data);
    track(
        eventTypes.CHECKOUT_STEP_VIEWED,
        Object.assign(
            {
                step: data.currentStage,
                shipping_method: data.shippingMethod,
            },
            baseData
        )
    );
}

function trackNavClicked() {
    track(eventTypes.NAV_CLICKED, {
        clicked_text: 'Traeger',
        nav_type: 'Top Navigation',
        primary_header: 'Logo',
        secondary_header: 'Checkout',
    });
}

/**
 * @typedef {'customer section' |'shipping section' | 'payment section' | 'order summary'} Location
 */

/**
 * Segment event for when a link is clicked
 * @param {{ linkText: string, linkUrl: string | null, linkLocation: Location, open?: boolean}} props
 */
function trackLinkClicked(props) {
    track(eventTypes.LINK_CLICKED, {
        link_type: 'checkout',
        link_text: props.linkText,
        link_url: props.linkUrl,
        link_location: props.linkLocation,
        open: props.open,
    });
}

/**
 * Segment event for when a ID.me verified
 * @param {{ linkText: string, linkUrl: string | null, linkLocation: Location, open?: boolean}} props
 */
function trackIDmeVerified(data) {
    track(eventTypes.IDME_VERIFIED, data);
}

/**
 * Segment event for when a link is clicked
 * @param {{ ctaText: string, ctaAction?: 'open' | 'close', ctaType: 'promotion' | 'payment', ctaLocation: Location, ctaHeader: string, campaignId?: string, campaignName?: string }} props
 */
function trackCTAClicked(props) {
    track(eventTypes.CTA_CLICKED, {
        cta_id: null,
        cta_type: props.ctaType,
        cta_action: props.ctaAction,
        cta_text: props.ctaText,
        cta_header: props.ctaHeader,
        cta_location: props.ctaLocation,
        link_url: props.linkUrl || null,
        campaign_name: props.campaignName,
        campaign_id: props.campaignId,
    });
}

function trackCouponApplied(data) {
    track(eventTypes.COUPON_APPLIED, data);
}

function trackCouponDenied(data) {
    track(eventTypes.COUPON_DENIED, data);
}

function trackCouponRemoved(data) {
    track(eventTypes.COUPON_REMOVED, data);
}

function identify(data) {
    var userData = getUserData(data);
    var hash = getEmailHash(userData.email);
    if (data.order) {
        userData.customer_group = data.order.customer_group;
    }
    userData.monetate_id = getCookie('mt.v').substring(6) || '';
    userData.guidh = hash;

    if (!userData) {
        return;
    }
    if (hash) {
        setGUIDHashCookie(hash);
        window.analytics.identify(hash, userData);
    }
}

function getCookie(cookieID) {
    var cookies = document.cookie.split(';');
    var cookieValue = '';

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.startsWith(cookieID + '=')) {
            cookieValue = cookie;
            break;
        }
    }
    return cookieValue;
}

module.exports = {
    identify: identify,
    trackCheckoutStepCompleted: trackCheckoutStepCompleted,
    trackCheckoutStepViewed: trackCheckoutStepViewed,
    trackNavClicked: trackNavClicked,
    trackLinkClicked: trackLinkClicked,
    trackCTAClicked: trackCTAClicked,
    trackCouponApplied: trackCouponApplied,
    trackCouponDenied: trackCouponDenied,
    trackCouponRemoved: trackCouponRemoved,
    trackIDmeVerified: trackIDmeVerified,
    // trackCouponEntered,
    // trackGiftCardEntered,
    // trackGiftCardApplied,
    // trackGiftCardDenied,
    // trackGiftCardRemoved,
    // trackLinkClicked,
    // trackSMSSignup,
};
